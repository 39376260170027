<template>
  <div>
    <div class="columns">
      <div class="column is-2">
        <figure class="image is-128x128">
          <img class="image-cropper" :src="XbortGetMedia(userImage, 'medium')">
          <div class="is-overlay center_down has-text-centered">
            <button class="button is-outline is-primary is-small" @click="changeImage"><b-icon icon="camera" /></button>
          </div>
        </figure>
      </div>
      <div class="column">
        <b-field label="الاسم">
          <b-input v-model="userName" minlength="6" maxlength="64" placeholder="الاسم..."></b-input>
        </b-field>
        <b-field class="is-pulled-right">
          <button class="button is-primary" @click.prevent="updateProfile">حفظ</button>
        </b-field>
      </div>
    </div>
    <Media :image="userImage" :media="userMedia"></Media>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Media from './Profileimage'

export default {
  data () {
    return {
      userId: null,
      userEmail: null,
      userName: null,
      userLink: null,
      userImage: null,
      userMedia: []
    }
  },
  components: {
    Media
  },
  created () {
    this.getProfile()
  },
  methods: {
    getProfile: function () {
      let params = {
        appid: this.$store.state.config.XbAppID,
        type: 'data',
        request: 'km_profiles',
        getby: `userId:${this.getUser},userEmail:${this.getEmail}`
      }
      this.XbortGetRequest('', params, result => {
        if (result.result) {
          if (result.data.length === 0) {
            let params = {
              appid: this.$store.state.config.XbAppID,
              type: 'data',
              request: 'km_profiles',
              data: JSON.stringify({ userId: this.getUser, userEmail: this.getEmail })
            }
            this.XbortRequest('', 'post', params, result => {
              if (result.result) {
                this.getProfile()
              } else {
                this.errorMessage(result.data)
              }
            })
          } else {
            this.userId = result.data[0]._id
            this.userEmail = result.data[0].userEmail
            this.userName = result.data[0].userName ? result.data[0].userName : ''
            this.userLink = result.data[0].userLink ? result.data[0].userLink : ''
            this.userImage = result.data[0].userImage ? result.data[0].userImage : ''
            this.userMedia = result.data[0].userMedia ? result.data[0].userMedia : []
          }
        } else {
          this.errorMessage(result.data)
        }
      })
    },
    updateProfile: function () {
      let userData = {
        userId: this.getUser,
        userEmail: this.getEmail
      }
      if (this.userImage !== null) {
        userData.userImage = this.userImage
      }
      if (this.userMedia.length > 0) {
        userData.userMedia = this.userMedia
      }
      if (this.userName !== null) {
        userData.userName = this.userName
        userData.userLink = this.userName.toString().toLowerCase().trim().replace(/\W+/g, '_')
      }
      let params = {
        appid: this.$store.state.config.XbAppID,
        type: 'data',
        request: 'km_profiles',
        id: this.userId,
        data: JSON.stringify(userData)
      }
      this.XbortRequest('', 'put', params, result => {
        if (result.result) {
          this.successSave()
          this.getProfile()
        } else {
          this.errorMessage(result.data)
        }
      })
    },
    changeImage: function () {
      this.$store.dispatch('showForm', { show: true, name: 'profile' })
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getEmail',
      'getLoader',
      'getError'
    ])
  }
}
</script>

<style>
  .is-overlay {
    margin: 0 auto;
    top: auto;
    bottom: 0;
    right: auto;
    left: auto;
  }
  .image-cropper {
    /* width: 55px;
    height: 55px; */
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    /* border: 1px solid white; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
  .image-cropper img {
    display: inline;
    margin: 0 auto;
  }
</style>
